@import "../../style/Colors";

.download-hero {
  position: relative;
  background-image: url('../../assets/hero-img.jpg');
  background-size: cover;
  background-position: center top;
  margin-top: -80px;
  padding-top: 8rem;
  margin-bottom: 0rem;
  padding-bottom: 4rem;

  &:before {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(160deg, transparent 30%, $hardy-brand-color 150%);
    z-index: 0;
  }

  @media (min-width: 992px) {
    // padding-top: 15vh;
    // padding-bottom: 15vh;
  }

  .container {
    z-index: 1;
    position: relative;
  }

  h1 {
    letter-spacing: 1px;
    font-family: din-condensed, sans-serif;
    font-weight: 400;
    font-style: normal;
    // text-transform: uppercase;
    max-width: 50rem;
    margin-left: auto;
    margin-right: auto;

    @media (min-width: 992px) {
      font-size: 4rem;
    }
  }
}