$hardy-brand-color: #df563d; //#FE7762; //#EA5F00; //#0000FF; 
$hardy-black: #1D1D1D;
$hardy-light-blue: #F4F6F9;
$hardy-secondary-gray: #9B928E;
$hardy-lightgray: #F2EDE9;

$hardy-invert: #6967e0; //invert($hardy-brand-color);

$amrap-color: #dc76c8;
$po-color: #f7e641;


// Color system

$white:    #fff;
$gray-100: #f8f9fa;
$gray-200: #e9ecef;
$gray-300: #dee2e6;
$gray-400: #ced4da;
$gray-500: #949DAE; //#adb5bd;
$gray-600: #6c757d;
$gray-700: #495057;
$gray-800: #4A4A4A;
$gray-900: #212529;
$black:    $hardy-black; //#000;

$grays: ();
// stylelint-disable-next-line scss/dollar-variable-default
$grays: map-merge(
  (
    "100": $gray-100,
    "200": $gray-200,
    "300": $gray-300,
    "400": $gray-400,
    "500": $gray-500,
    "600": $gray-600,
    "700": $gray-700,
    "800": $gray-800,
    "900": $gray-900
  ),
  $grays
);

$blue:    #007bff;
$indigo:  #6610f2;
$purple:  #6f42c1;
$pink:    #e83e8c;
$red:     #dc3545;
$orange:  #fd7e14;
$yellow:  #ffc107;
$green:   #28a745;
$teal:    #20c997;
$cyan:    #17a2b8;

$colors: ();
// stylelint-disable-next-line scss/dollar-variable-default
$colors: map-merge(
  (
    "blue":       $blue,
    "indigo":     $indigo,
    "purple":     $purple,
    "pink":       $pink,
    "red":        $red,
    "orange":     $orange,
    "yellow":     $yellow,
    "green":      $green,
    "teal":       $teal,
    "cyan":       $cyan,
    "white":      $white,
    "gray":       $gray-600,
    "gray-dark":  $gray-800
  ),
  $colors
);

$primary:       $hardy-brand-color; //$blue;
$secondary:     $gray-500;
$success:       $green;
$info:          $cyan;
$warning:       $yellow;
$danger:        $red;
$light:         $hardy-light-blue; //$gray-100;
$dark:          $gray-800;

$theme-colors: ();
// stylelint-disable-next-line scss/dollar-variable-default
$theme-colors: map-merge(
  (
    "primary":    $primary,
    "secondary":  $secondary,
    "success":    $success,
    "info":       $info,
    "warning":    $warning,
    "danger":     $danger,
    "light":      $light,
    "dark":       $dark
  ),
  $theme-colors
);