@import "../../style/Colors";

.fit-menu {
  background: rgba(0, 0, 0, 0.885);
  backdrop-filter: blur(5px);
  font-weight: bold;
  padding-top: 0.938rem;
  padding-bottom: 0.938rem;
  font-family: din-condensed, sans-serif;
	font-weight: 400;
	text-transform: uppercase;
	font-style: normal;
	font-size: 1.125rem;
	letter-spacing: 0.5px;
  padding: 0;
  transition: background-color 1s, backdrop-filter 1s;
  min-height: 80px;

  &.fit-menu--transparent {
    background-color: transparent;
    backdrop-filter: none;
  }
}
@media (max-width: 768px) {
  .navbar-collapse {
    background: rgba(0, 0, 0, 0.885);
    backdrop-filter: blur(5px);
    padding-top: 2rem;
    padding-bottom: 2rem;
  
    .dropdown-menu {
      background-color: transparent;

      .dropdown-item {
        color: #fff;
      }
      .dropdown-divider {
        border-color: rgba(255,255,255,0.1);
      }
    }
  }
  .navbar-nav .nav-user-dropdown {
    text-align: center;
    margin-top: 1rem;
    min-width: 190px;
  }
}
.navbar-nav {
  padding-left: 1rem;
  padding-right: 1rem;

	.btn-nav {
		color: white;
		font-size: 1.125rem;
		letter-spacing: 0.5px;
		background: transparent;
		padding: 0.65rem 1.5rem 0.6rem 1.5rem;

		&:hover {
			color: $hardy-brand-color;
		}
    &:focus,
    &:active {
      color: white;
    }
	}
	.nav-link {
		padding-top: 0.75rem;
    border-radius: 40px;
    outline: none;

    &:hover,
    &:active,
    &:focus {
      background: rgba(255,255,255,0.15);
    }
	}
}
.navbar-toggler {
  margin: 20px 1rem;
}
.navbar-expand .navbar-nav .nav-link {
    padding-right: 1.5rem;
    padding-left: 1.5rem;
    
    @media (max-width: 992px) {
      padding-right: 1rem;
      padding-left: 1rem;
    }
}

.navbar-brand {
  position: absolute;
  left: 50%;
  top: 13px;
  transform: translateX(-50%);
  padding: 0.625rem 1.5rem;
  border-radius: 40px;
  margin-right: 0;
  transition: background 0.2s ease-in-out;
  outline: none;
  cursor: pointer;

  @media (max-width: 992px) {

  }

  &:hover,
  &:focus,
  &:active {
    background: rgba(255,255,255,0.15);
  }
}
.nav-logo {
	height: 28px;
	// margin-right: 30px;
	// margin-left: 1rem;
	margin: 0;

	// @media (min-width: 992px) {
	// }
}
.nav-search {
	position: relative;

	.search-field {
    border-radius: 20rem;
    font-size: 1rem;
    height: 50px;
    padding-left: 3rem;
    padding-right: 1.5rem;
    font-weight: bold;
    color: #949DAE;
    background-color: transparentize(#474142, 0.6);
    border-color: transparent;
    font-family: din-2014, sans-serif;
    color: white;
    width: 11rem;

    &:hover {
    	border-color: rgba(255,255,255,0.2);
    	// box-shadow: 0 0 0 3px transparentize(#FE7762, 0.7);
    }
    &:focus {
    	border-color: #FE7762;
    }
  }
  svg {
    position: absolute;
    left: 1rem;
    top: 0.85rem;
  }
}

.fit-body {
  margin-top: 80px;
}
