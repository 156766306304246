@keyframes flash-fade-out {
   0%   {   opacity: 100%;   }
   70%  {   opacity: 100%;   }
   100% {   opacity: 0%;     }
}
@keyframes css-fade-in {
   0%   {   opacity: 0%;   }
   100% {   opacity: 100%;     }
}
/* -------- */

h1, h2, h3, h4, h5 {
  &.caps-condensed {
    font-family: din-condensed, sans-serif;
    font-weight: 400;
    font-style: normal;
    text-transform: uppercase;
    // letter-spacing: 0.5px;
  }
}

h2 {
}
h3 {
}
h4 {
  margin-bottom: 1.5rem;
}
h5 {
  margin-bottom: 1rem;
}
a {
  transition: color 0.2s;
  
  &:hover {
  }
}

.container {
  padding-left: 1rem;
  padding-right: 1rem;
}

.hidden-xs {
  @media (max-width: 768px) {
    display: none; 
  }
}

input {
  &:hover {
    box-shadow: $input-focus-box-shadow;
  }
  &:focus {
    border-color: $hardy-brand-color;
  }

  &.input-lg {
    height: 50px;
    border-radius: 10px;
  }
  &.input-short {
    max-width: 5rem;
  }
}
.custom-switch label {
  cursor: pointer;
}
.form-label {
  text-transform: uppercase;
  letter-spacing: 0.3px;
  font-family: din-condensed, sans-serif;
  // color: $gray-500;
  font-weight: 400;
  font-style: normal;
  font-size: 1rem;
}
.input-group-append > .input-group-append-switch {

  > .custom-control.custom-switch {
    margin: -0.375rem -0.75rem;
    padding: 0.375rem 0.8rem 0.375rem 3rem;
    cursor: pointer;

    label {
      text-transform: uppercase;
      letter-spacing: 0.3px;
      font-family: din-condensed, sans-serif;
      font-weight: 400;
      font-style: normal;
      font-size: 0.8rem;
      line-height: 1.5rem;
      cursor: pointer;
    }
  }

  &:hover {
    // border-color: $hardy-brand-color;
    cursor: pointer;
    background-color: transparentize($hardy-brand-color, 0.9);
  }
}
.custom-input-group {
  display: inline-block;
  width: 100%;
  position: relative;
  text-align: right;
  // height: 38px;

  .form-control {
    padding-right: 2.4rem;
    padding-left: 0;
    text-align: right;
    display: inline-block;
    vertical-align: middle;

    // Hide up/down arrows
    /* Chrome, Safari, Edge, Opera */
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    /* Firefox */
    &[type=number] {
      -moz-appearance: textfield;
    }
  }
  &.custom-input-group--2 {

  }
  &.custom-input-group--3 {

  }
  &.custom-input-group--intensity1 {
    .form-control {
      max-width: 4.2rem;
      padding-right: 1.9rem;
    }
  }
  &.custom-input-group--intensity2 {
    .form-control {
      max-width: 4.7rem;
      padding-right: 1.9rem;
    }
  }
  &.custom-input-group--reps {
    .form-control {
      max-width: 3rem;
      padding-right: 0.9rem;
    }
    span {
      right: 0.9rem;
    }
  }
  &.custom-input-group--reps-amrap {
    .form-control {
      max-width: 4rem;
      padding-right: 1.7rem;
    }
    span {
      font-size: 120%;
      top: 3px;
      line-height: 34px;
      right: 0.9rem;
    }
  }
  &.custom-input-group--rest {
    .form-control {
      max-width: 5.4rem;
      padding-right: 2.6rem;
    }
  }
  &.custom-input-group--bodyweight-time {
    .form-control {
      max-width: 5.4rem;
      padding-right: 2.6rem;
    }
  }
  &.custom-input-group--weight {
    .form-control {
      max-width: 4.6rem;
      padding-right: 2.5rem;
    }
  }
  span {
    position: absolute;
    top: 1px;
    right: 1rem;
    color: $gray-500;
    pointer-events: none;
    line-height: 37px;
  }
}

.input-group.input-group--flex {
  display: inline-flex;
  width: auto;
}
.inputs-inline {
  > p > .form-control,
  > .form-control,
  > span, 
  > p > span {
    display: inline-block;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }
  .form-control {
    max-width: 4rem;
  }
}
.unit-select {
  .unit-select__control {
    // height: 50px;
    border-radius: $input-border-radius;
    border-color: $input-border-color;
    color: $input-color;
    
    &:hover {
      box-shadow: $input-focus-box-shadow;
      border-color: $hardy-brand-color;
    }
    &.unit-select__control--is-open {
      border-color: $hardy-brand-color;
      box-shadow: $input-focus-box-shadow;
    }
    &.unit-select__control--is-focused {
      border-color: $hardy-brand-color;
      box-shadow: $input-focus-box-shadow;

      &:hover {
        // border-color: $hardy-brand-color;
        box-shadow: $input-focus-box-shadow;
      }
    }
  }
  .unit-select__value-container {
    padding: 3px $input-padding-x;
  }
  .unit-select__option {
    .text-muted.float-right {
      line-height: 30px;
    }
    &.unit-select__option--is-focused {
      color: $black;
      background: transparentize($hardy-light-blue, 0.3);

      .icon-exercise {
        box-shadow: 0 0 0 3px transparentize($hardy-brand-color, 0.8);
      }
    }
    &.unit-select__option--is-selected {
      background: transparentize($hardy-light-blue, 0.3);
      color: $hardy-brand-color;
      // font-weight: bold;
      padding-left: 9px;
      border-left: 3px solid $hardy-brand-color;

      .icon-exercise {
        box-shadow: 0 0 0 3px transparentize($hardy-brand-color, 0.8), 0 0 0 1px $hardy-brand-color;
        // box-shadow: 0 0 0 1px $hardy-brand-color;
      }
    }
  }
}
.caps-title {
  color: $gray-500;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  font-family: din-condensed, sans-serif;
}
.text-large {
  font-size: 1.25rem;
  font-weight: 300;
}
.has-overlay {
  border-bottom: dashed 1px $gray-200;
  cursor: pointer;
}

/* -------- */

.dropdown {
  .dropdown-menu {

    .dropdown-header {
      letter-spacing: 0.5px;
      font-family: din-condensed, sans-serif;
      font-weight: 400;
      font-style: normal;
      text-transform: uppercase;
      font-size: 1rem;
      // padding: 0.5rem 1.5rem 0.5rem 1.5rem;
      color: $gray-500;
    }
    .dropdown-item {
      letter-spacing: 0.5px;
      font-family: din-condensed, sans-serif;
      font-weight: 400;
      font-style: normal;
      text-transform: uppercase;
    }
  }
}
.inline-dropdown {
  display: inline-block;
  margin-left: 0.5rem;
  margin-right: 0.5rem;

  .btn {
    border-radius: 6px;
    padding-right: 0.7rem;
    padding-left: 0.7rem;

    &:focus,
    &:active {
      color: #495057;
      background-color: #fff;
      border-color: #df563d;
      outline: 0;
      box-shadow: 0 0 0 0.2rem rgba($hardy-brand-color, .25) !important;
    }
  }
}

/* -------- */

.btn {
  text-transform: uppercase;
  letter-spacing: 0.7px;
}
.btn-lg {
  min-height: 50px;
  line-height: 1.55;

  &.btn-primary:hover {
    box-shadow: -10px 10px 15px transparentize(#FF4B2F, 0.8);
  }
}
.btn-link {
  &:hover {
    text-decoration: none;
  }
}
.btn--facebook {
  background: #3b5998;
  border-color: transparent;
  color: white;

  &:hover {
    color: white;
    background: darken(#3b5998, 5%);
    box-shadow: -10px 10px 15px transparentize(#3b5998, 0.8);
  }

  i {
    margin-right: 1rem;
  }
}
.appstore-img {
  padding: 0;
  margin: 0;
  border: 0;
  border-radius: 7px;
  height: 50px;
  background-color: transparent;
  display: block;
  transition: box-shadow 0.3s;
  box-shadow: 0 0 0 3px transparent;
  outline: none;

  &:hover,
  &:focus,
  &:active {
    background: none;
    border: none;
    box-shadow: 0 0 0 3px $hardy-brand-color;
  }

  img {
    width: auto;
    height: 100%;
    max-width: 100%;
  }

  &.appstore-img--apple {
    border-radius: 10px;
  }
  &.appstore-img--google {
  }
}
.btn-with-icon {
  svg {
    // margin-top: -0.3em;
    // margin-bottom: -0.5em;
    height: 1.2em;
    width: 1.2em;
    margin-right: 0.1em;
  }
}
.btn-icon {
  background: none;
  border: 0;
  padding: 0px 0 0 0px;
  height: 35px;
  width: 35px;
  border-radius: 100px;
  line-height: 1.5;

  svg {
    height: 20px;
    width: 20px;
  }
  &.btn-icon--lg {
    height: 50px;
    width: 50px;

    svg {
      height: 30px;
      width: 30px;
    }
  }
  &.btn-trash {
    color: $gray-500;

    &.btn-primary:hover {
      color: #fff;
    }
  }
}
.btn-outline-secondary {
  border-color: #CFD3DA;
  color: $gray-800;

  &:hover {
    background-color: transparent;
    color: $hardy-brand-color;
    border-color: $hardy-brand-color;
  }
  &:active {

  }
}
.btn-delete {
  &:hover {
    color: $danger;
  }
}
.btn-shadow {
  box-shadow: -10px 10px 15px transparentize(#FF4B2F, 0.8);
}
.btn-tag {
  display: inline-block;
  margin-bottom: 0;
  
  input {
    opacity: 0;
    position: absolute;
  }
  div {
    cursor: pointer;
    // padding: 0 6px;
    // height: 28px;
    // line-height: 26px;
    // display: inline-block;
    // font-size: 0.8rem;
    // font-weight: normal;
    // background: transparent;
    // border: 1px dashed $gray-400;
    // border-radius: 6px;
  }
  input:not(:checked) + div {
    border: 1px dashed $gray-400 !important;
    background: transparent !important;
    color: inherit !important;

    svg {
      display: none;
    }
  }
  input:checked + div {
    svg.icon-x {
      display: none;
    }
  }
  .custom-switch {
    line-height: 1.8;
  }
  &.btn-tag--amrap {
    &:hover {
      input:checked + div {
        opacity: 0.9;

        svg.icon-x {
          display: inline;
        }
        svg.icon-check {
          display: none;
        }
      }
      input:not(:checked) + div {
        border: 1px solid $amrap-color !important;
        background: transparentize($amrap-color, 0.9) !important;
        box-shadow: 0 0 0 2px transparentize($amrap-color, 0.9);
      }
    }
    input:focus {
      border: 1px solid $amrap-color !important;
      background: transparentize($amrap-color, 0.9) !important;
      box-shadow: 0 0 0 2px transparentize($amrap-color, 0.9);
        
        &:not(:checked) + div {
          border: 1px solid $amrap-color !important;
          background: transparentize($amrap-color, 0.9) !important;
          box-shadow: 0 0 0 2px transparentize($amrap-color, 0.9);
        }
        
        &:checked + div {
          box-shadow: 0 0 0 2px transparentize($amrap-color, 0.9);

          svg.icon-x {
            display: inline;
          }
          svg.icon-check {
            display: none;
          }
        }
    }
  }
  &.btn-tag--po {
    &:hover {
      input:checked + div {
        opacity: 0.9;

        svg.icon-x {
          display: inline;
        }
        svg.icon-check {
          display: none;
        }
      }
      input:not(:checked) + div {
        border: 1px solid $po-color !important;
        background: transparentize($po-color, 0.9) !important;
      }
    }
    input:focus + div {
      border: 1px solid $po-color !important;
      background: transparentize($po-color, 0.9) !important;
    }
  }
}
a.btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

/* -------- */

.or-divider {
  line-height: 33px;
  color: $gray-500;
  font-family: din-condensed, sans-serif;
  font-size: 1.125rem;
}

.w-100 {
  width: 100%;
}

.routine-img-holder {
  float: left;
  display: inline-block;
  width: 4rem;
  height: 4rem;
  background-color: #F2EDE9;
  border-radius: 10px;
  position: relative;

  &.routine-img-holder--subscribed {
    background-color: #F3E4D8;
  }
  &.routine-img-holder--own {
    background-color: #E1F5DC; 
  }

  &.routine-img-holder--create {
    background-color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    border: 1px solid #F2EDE9;
    font-size: 35px;
    font-weight: lighter;
    text-align: center;
    color: #D0C3BA;
  }

  @media (max-width: 768px) {
    width: 3.5rem;
    height: 3.5rem;
  }

  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #fff;
    height: 60%;
    width: 60%;
    path {
      fill: #fff;
    }
  }

  &.routine-img-holder--lg {
    width: 10rem;
    height: 10rem;

    @media (max-width: 768px) {
      width: 5rem;
      height: 5rem;
    }
  }
  &.routine-img-holder--exercise {
    width: 2.5rem;
    height: 2.5rem;
    background-color: lighten($hardy-brand-color, 30%);
  }
}

.routine-link {
  display: block;
  font-size: 1.25rem;
  font-weight: 300;

  .routine-img-holder {
    transition: box-shadow 0.2s;
  }

  &:hover {
    text-decoration: none;

    .routine-img-holder {
      box-shadow: $input-focus-box-shadow, 0 0 2px rgba(0,0,0,0.1);
    }
  }
  h5 {
    margin-bottom: 0;
    padding-top: 0.4rem;
    font-weight: bold;
    @media (max-width: 768px) {
      font-size: 1.125rem;
      padding-top: 0.2rem;
    }
  }
  &.routine-link--create {
    h5 {
      margin-top: 0.9rem;
    }
  }
  .routine-username {
    @media (max-width: 768px) {
      font-size: 1rem;
    }
  }
  .routine-link-stats {
    padding-top: 0.2rem;
    display: inline-block;

    @media (max-width: 768px) {
      font-size: 1rem;
    }
  }
  .btn-icon {
    margin-top: 0.2rem;
  }
}
.routine-private-icon {
  position: absolute;
  bottom: -8%;
  right: 5%;
  border: 1px solid rgba(150,150,150,0.2);
  background: #F4F6F9;
  border-radius: 100%;
  height: 20%;
  min-height: 22px;
  width: 20%;
  min-width: 22px;

  svg {
    width: 80%;
    height: 80%;

    path {
      fill: #949DAE;
    }
  }
}

/* -------- */

.page-loading {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 4rem;
}

//----

.search-group {
  position: relative;
  padding-top: 3rem;
  margin-bottom: 3rem;

  @media (max-width: 768px) {
    padding-top: 2rem;
    margin-bottom: 2rem;
  }


  .search-field {
    border-radius: 20rem;
    font-size: 1.25rem;
    height: 4rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    font-weight: bold;
    color: #949DAE;
    background-color: #F4F6F9;
    border-color: transparent;

    &.search-field--dark {
      background: rgba(0, 0, 0, 0.7);
      border-color: transparent;
    }
    &:focus {
      border-color: $hardy-brand-color;
    }
  }
  .search-field-icon {
    position: absolute;
    right: 2.5rem;
    top: 1.1rem;
  }
}
.search-suggestion-link {
  border-bottom: 1px solid rgba(50,50,50,0.1);
  text-decoration: none;
  color: $gray-500;

  &:hover {
    text-decoration: none;
    color: $hardy-brand-color;
    border-bottom: 1px solid rgba(50,50,50,0.3);
  }
}


/* --- Workout --- */
.content-area {
  // background-color: white;
  // border-radius: 10px;
  // padding: 30px;
}
.routine-header {
  background-color: #F4F6F9;
  padding-bottom: 1.5rem;
  padding-top: 1.5rem;

  .btn-icon {
    margin-top: -0.45rem;
    margin-bottom: -0.45rem;
  }

  h2 {
    @media (max-width: 768px) {
      font-size: 1.6rem;
    }
  }
}

.template-select-box {
  text-align: center;
  padding: 1.2rem 0;
  border: 1px solid $gray-400;
  cursor: pointer;
  border-radius: 6px;
  display: block;
  margin-bottom: 1rem;
  outline: none;
  
  &:focus,
  &:active,
  &:hover {
    text-decoration: none;
    border-color: $hardy-brand-color;
  }
}
.template-select-box-skip {
  color: $gray-500;
  display: block;
  text-align: center;
  padding: 1.2rem 0;
  border-radius: 6px;
  outline: none;

  &:focus,
  &:active,
  &:hover {
    text-decoration: none;
    background: transparentize($hardy-light-blue, 0.3);
  } 
}

.input-fake-editable {
  position: relative;

  input {
    border: 0;
    font-size: 1.4rem;
    font-weight: bold;
    margin-left: -$input-padding-x;
    padding-top: $input-padding-y / 4;
    padding-bottom: $input-padding-y / 4;
    margin-bottom: $input-padding-y / 2;
    margin-top: -0.85rem;
  }
  &.input-fake-editable--dark {
    input {
      background-color: rgba(0,0,0,1);
      color: white;
      margin-bottom: -0.7rem;
      margin-top: -0.7rem;
      padding-top: 1em;
      padding-bottom: 1em;
    }
  }
  &.input-fake-editable--h1 {
    input {
      font-size: $h1-font-size;
    }
  }
  &.input-fake-editable--h3 {
    input {
      font-size: $h3-font-size;
    }
  }
}
.textarea-fake-editable {
  position: relative;

  textarea {
    border: 0;
    // font-size: 1rem;
    margin-left: -$input-padding-x;
    margin-top: -0.15rem;
  }
  .icon-pencil {
    position: absolute;
    left: -32px;
    top: 10px;
    height: 26px;
    width: 30px;
    color: $gray-500;
    display: none;
  }
}
.btn-add-sm,
.btn-add-lg {
  text-align: left;
  border: 0;
  border-radius: 0;
  width: 100%;
  background: transparent;
  padding: 0;
  color: inherit;
  letter-spacing: 0.5px;
  font-family: din-condensed, sans-serif;
  font-weight: 400;
  font-style: normal;
  text-transform: uppercase;

  .add-plus {
    // background: $hardy-brand-color;
    background: transparent;
    // border: 1px solid $hardy-brand-color;
    color: $hardy-brand-color;
    // border-radius: 20rem;
    // width: 23px;
    // height: 25px;
    display: inline-block;
    width: 0.8em;
    // line-height: 23px;
    text-align: center;
    font-size: 1.4em;
    // vertical-align: middle;
    
    svg {
      position: relative;
      left: -3px;
    }
  }
  &:focus,
  &:hover,
  &:active {
    background: transparent;
    border: 0;
    color: $hardy-brand-color;

    .add-plus {
      // border: 1px solid currentColor;
      // background: $hardy-brand-color;
    }
  }
}
.btn-subscribe {
  transition: padding-right 0.5s; 
  padding-left: 1.1rem;
  padding-right: 1.1rem;

  svg {
    height: 1.5em;
    width: 1.5em;
    margin-top: -0.1em;
    margin-right: 0.1em;
    margin-left: -0.2em;
  }
  .show-as-default {
    display: block;
    pointer-events: none;
    transition: opacity 0.5s, transform 0.3s;
  }
  .show-on-hover {
    pointer-events: none;
    transition: opacity 0.5s, transform 0.3s;
    position: absolute;
    opacity: 0;
    margin-left: -0.1em;
    transform: translateY(-10px);

    svg {
      width: 1.6em;
      margin-top: -0.2em;
      margin-right: -0.1em;
    }
  }
  &:not(.disabled):hover {
    padding-right: 3rem;

    .show-as-default {
      opacity: 0;
      transform: translateY(10px);
    }
    .show-on-hover {
      opacity: 1;
      transform: translateY(0px);
    }
  }
  &.disabled {
    &:hover {
      color: #949DAE;
      border-color: #CFD3DA;
      cursor: not-allowed;
    }
  }
}
.btn-start {
  border-radius: 8px;

  @media (max-width: 768px) {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
}
.btn-add-lg {
  font-size: 1.25rem;
}
.btn-add-sm {
  font-size: 1rem;
}
.btn-add-row {
  height: 52px;
  transition: background 0.5s;
  outline: none;
  padding-left: 1rem;
  padding-right: 1rem;
  // text-align: center;

  &:hover {
    background: transparentize($hardy-light-blue, 0.3);
    cursor: pointer;
  }
  &:active {
    background: none;
    border-color: #F4F6F9;
  }
  &:focus {
    box-shadow: none;
    background: transparentize($hardy-light-blue, 0.3);
    color: $hardy-brand-color;
  }
}
.editable-text {
  cursor: pointer;
  transition: color 0.2s;
  display: inline-block;
  
  svg {
    width: 23px;
    animation: 2s flash-fade-out;
    opacity: 0;
    transition: opacity 0.2s;

    @media (max-width: 768px) {
      opacity: 0.3;
    }
  }
  .editable-text-empty {
    color: $gray-500;
  }

  &:hover {
    color: $hardy-brand-color;
    text-decoration: none;

    .editable-text-empty {
      color: $hardy-brand-color;
    }

    svg {
      opacity: 1;
      color: $hardy-brand-color;
    }
  }
  .edit-pencil:focus,
  .edit-pencil:active {
    svg {
      opacity: 1;
      color: $hardy-brand-color;
    }
  }
}
.routine-description {
  .w-md-editor {
    font-family: inherit;
    box-shadow: none;
    border: 1px solid #ced4da;
    border-radius: 6px;
  }
  .w-md-editor-text {
    font-size: 16px;
    line-height: 1.5;
  }
  .wmde-markdown {
    font-family: inherit;
    font-size: 16px;
    line-height: 1.5;
  }
  .w-md-editor-bar {
    margin-top: -12px;
    width: 100%;
    background: #f3f6f9;
    height: 10px;
    border-radius: 0 0 6px 6px;
  }
  .textarea-subtext {
    color: rgba(0,0,0,0.3);
    a {
      color: inherit;
      border-bottom: 1px solid rgba(0,0,0,0.05);
      &:hover {
        text-decoration: none;
        border-bottom: 1px solid rgba(0,0,0,0.15);
      }
    }
  }
}
.btn-add-customexercise {
  border-top: 1px solid rgba(0,0,0,0.1);
}

////////////////////////

.routine-workout {
  margin-bottom: 3rem;

  .routine-workout-header {
    background: #333333; //#231F20;
    padding: 1rem;
    color: white;

    &.routine-workout-header--clickable {
      border-radius: 10px;
      cursor: pointer;
      // background: none;
      // border: 2px dashed #333333;
      // color: #333333;
    }

    h3 {
      margin-bottom: 0;
    }
    .form-group {
      margin-bottom: 0;
    }
    .input-borderless {
      input {
        background-color: transparentize(#474142, 0.6);
        border-color: transparent;
        margin: 0;
        color: white;
      }
      .icon-pencil {
        display: none;
        position: absolute;
        left: -32px;
        top: 10px;
      }
    }
  }
  .routine-workout-body {
    background: #F4F6F9;
    padding: 1rem;
  }
  p.routine-workout-description {
    color: #4A4A4A;
    font-weight: 300;
  }
  .routine-workout-exercise {
    background-color: #fff;
    box-shadow: 0 4px 60px rgba(0,0,0,0.07);
    border-radius: 10px;
    padding: 0.5rem 1.5rem;

    @media (max-width: 768px) {
      padding: 0.5rem 1rem;
    }

    &.routine-workout-exercise--add {
      display: block;
      width: 100%;
      height: 70px;
      background-color: transparent;
      box-shadow: none;
      border: 2px dashed rgba(0,0,0,0.07);
      cursor: pointer;
      transition: color 0.2s, border-color 0.2s;
      outline: none;
      color: $gray-800;

      .btn-add-lg {
        line-height: 1em;
      }

      &:hover,
      &:focus {
        border-color: transparentize($hardy-brand-color, 0.5);
        color: $hardy-brand-color;
      }
    }

    h4 {
      // display: inline-block;
      vertical-align: middle;
      margin-bottom: 1rem;

      &:first-letter {
        text-transform: capitalize;
      }
    }
    .woe-chevron {
      cursor: pointer;
      width: 1.2rem;
      height: 100%;
      margin-right: 0.7rem;
      margin-left: -0.15rem;
      flex-shrink: 0;
    }

    p.routine-woe-description {
      font-weight: 300;
    }

    & + .routine-workout-exercise {
      margin-top: 1rem;
    }

    .btn-icon {
      color: $gray-500;
      transition: opacity 0.2s;

      &:focus {
        opacity: 1;
      }

      &.btn-primary:hover {
        color: #fff;
      }
      @media (max-width: 768px) {
        width: 20px;
        height: 20px;
        line-height: 20px;

        svg {
          width: 100%;
        }
      }
    }

    .routine-woe-header {
      padding-top: 0rem;
      padding-bottom: 0;
      margin-bottom: 0rem;

      padding-left: 0.5rem;
      padding-right: 0.5rem;
      margin-left: -1.5rem;
      margin-right: -1.5rem;

      &.routine-woe-header--bordered {
        border-bottom: 1px solid #F4F6F9;
        padding-bottom: 1rem;
      }
    }
    .routine-woe-header-col-clickable {
      cursor: pointer;
      transition: background 0.5s;
      padding-top: 5px;
      padding-bottom: 5px;


      .btn-icon {
        animation: 1.5s flash-fade-out;
        opacity: 0;
        transition: opacity 0.2s;

        @media (max-width: 768px) {
          opacity: 1;
        }

        &:focus {
          opacity: 1;
        }
      }

      &:hover {
        background: transparentize($hardy-light-blue, 0.3);
        cursor: pointer;

        .btn-icon {
          // color: $hardy-brand-color;
          opacity: 1;
        }
      }
    }
    h5.routine-woe-header-sets {
      font-weight: lighter;
      display: inline-block;
      margin-bottom: 0;
    }

    .routine-wow-collapse-holder {
      margin-left: -1.5rem;
      margin-right: -1.5rem;
      padding-left: 1rem;
      padding-right: 1rem;
    }
    .routine-wow-collapse-inner {
      padding-left: 0.5rem;
      padding-right: 0.5rem;
    }

    .routine-woe-sets {
      margin-left: -1.5rem;
      margin-right: -1.5rem;

      @media (max-width: 768px) {
        margin-left: -1rem;
        margin-right: -1rem;
      }

      .routine-woe-sets-header {
        border-bottom: 1px solid rgba(128, 141, 165, 0.3); //#D9DCE3;
        padding-bottom: 0.5rem;
        margin-bottom: 0rem;
        line-height: 38px;
        margin-left: 1rem;
        margin-right: 1rem;

        @media (max-width: 768px) {
          margin-left: 1rem;
          margin-right: 1rem;
        }

        h5 {
          margin-bottom: 0;
          font-size: 1rem;
          font-weight: normal;
          text-transform: uppercase;
          letter-spacing: 0.5px;
          font-family: din-condensed, sans-serif;
        }
      }
      .routine-woe-sets-row-holder {
        label {
          line-height: 36px;
        }
        &.routine-woe-sets-row-holder--clickable {
          transition: background 0.5s;
          
          .btn-icon {
            animation: 1.5s flash-fade-out;
            opacity: 0;
            transition: opacity 0.2s;

            @media (max-width: 768px) {
              opacity: 1;
            }

            &:focus {
              opacity: 1;
            }
          }

          .set-number {
            display: inline-block;
            animation: 0.5s css-fade-in;
          }
          .set-number-drag {
            display: none;
          }

          &:hover {
            background: transparentize($hardy-light-blue, 0.3);
            cursor: pointer;

            .btn-icon {
              // color: $hardy-brand-color;
              opacity: 1;
            }
            .set-number {
              display: none;
            }
            .set-number-drag {
              display: inline-block;
              animation: 0.5s css-fade-in;
            }
          }
          .clickable-area-indicator {
            display: inline-block;
            border-radius: 6px;
            margin-right: -1rem;
            padding-right: 1rem;
            margin-left: -1rem;
            padding-left: 1rem;
            transition: background 0.2s;
            
            &:hover {
              background: rgba(160, 168, 183, 0.1);
            }
          }
          .po-highlight {
            transition: background 0.2s;
            
            &:hover {
              background: lighten($po-color, 20%);
            }
          }
          .amrap-highlight {
            transition: background 0.2s;
            
            &:hover {
              background: darken(#fbf2f9, 2%);
            }
          }
          h4 {
            // line-height: 38px;
            margin-bottom: 0;
            vertical-align: middle;
          }
          p {
            // line-height: inherit;
          }
          .amrap-tag,
          .po-tag {
            @media (max-width: 768px) {
              display: none;
            }
          }
        }
        &.routine-woe-sets-row-holder--edit {
          background: transparentize($hardy-light-blue, 0.3);
          position: relative;

          &:before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 3px;
            height: 100%;
            background: $hardy-brand-color;
          }
          .unit-select {
            width: 100%;
            line-height: 1.5;
            font-weight: 400;
            margin: -1px 0 -1px 1px;
          }
          .woe-cus-col-4--amrap {
            width: 100%;
          }
          .custom-input-group {
            top: -1px;
          }
        } 
      }
      .routine-woe-sets-row-drag {
        &:last-of-type .routine-woe-sets-row-holder .routine-woe-sets-row {
          // border-bottom: 0;
        }
        .routine-woe-sets-row {
          border-bottom: 1px solid rgba(160, 168, 183, 0.1); //#F4F6F9
        }
      }
      .routine-woe-sets-row-preview {
        &:last-of-type .routine-woe-sets-row-holder .routine-woe-sets-row {
          border-bottom: 0;
        }
        .routine-woe-sets-row {
          border-bottom: 1px solid rgba(160, 168, 183, 0.1); //#F4F6F9
        }
      }
      .routine-woe-sets-row {
        margin-bottom: 0rem;
        font-weight: 300;
        line-height: 38px;
        margin-left: 1rem;
        margin-right: 1rem;
        min-height: 50px;

        @media (max-width: 768px) {
          margin-left: 1rem;
          margin-right: 1rem;
        }

        > div[class*="col"] {
          padding-top: 5px;
          padding-bottom: 5px;
        }

        p {
          line-height: 1.6em;
        }

        h4 {
          line-height: 1.25em;
        }

        &.routine-woe-sets-row--amrap {
          > div[class*="col"] {
            padding-top: 0;
            padding-bottom: 0;
          }

          &.routine-woe-sets-row--amrapclickable {
            cursor: pointer;
          }
        }

        &.routine-woe-sets-row--preview {
          border-bottom: 1px solid rgba(160, 168, 183, 0.1); //#F4F6F9
          &:last-of-type {
            border-bottom: 0;
          }

          .amrap-tag,
          .po-tag {
            @media (max-width: 768px) {
              display: none;
            }
          }
        }

        .text-right {
          .custom-input-group {
            // margin-right: -1rem;
          }          
        }
        input.form-control {
          min-width: 3rem;
          // max-width: 5rem;
          // padding-right: 0.3rem;
          // margin-left: -0.8rem;
        }

        .routine-woe-sets-row--add-set {
          border-bottom: 1px solid rgba(160, 168, 183, 0.1); //#F4F6F9
        }

        .amrap-highlight {
          background: #fbf2f9;
          padding: 3px 0.5rem 3px 0.25rem;
          margin: -3px 0;
          line-height: 2rem;
          display: inline-block;
          border-radius: 6px;
          max-width: 100%;
        }
        .amrap-highlight--right {
          margin-left: -1rem;
        }
        .po-highlight {
          background: lighten($po-color, 25%);
          display: inline-block;
          margin: -5px -0.5rem;
          padding: 5px 0.5rem;
          // width: 100%;
          @media (min-width: 768px) {
            width: 100%;
          }
        }
      }

      &.routine-woe-sets--minimized {
        display: none;
      }
    }

    /* old */
    .routine-set-item {
      cursor: pointer;
      display: inline-block;
      min-width: 80px;
    }
    .routine-set-item + .routine-set-item {
      margin-left: 1rem;
    }
    .routine-set-item--amrap {
      background: yellow;
    }
  }
}
.woe-cus-row {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  // margin-right: -0.5rem;
  // margin-left: -0.5rem;
  justify-content: space-between;

  .woe-cus-col {
    &-1 {
      width: 2rem;
      // padding-left: 0.5rem;
      @media (max-width: 768px) {
        width: 0.9rem
      }
    }
    &-2 {
      width: 5rem;
      margin-right: 1rem;
      
      @media (max-width: 768px) {
        width: 4rem;
        margin-right: 0rem;
      }
    }
    &-3 {
      width: 3rem;
    }
    &-3-4 {
      width: 5.5rem;
      margin-right: 14rem;
      margin-left: -2rem;

      @media (max-width: 1199px) {
        width: 6rem;
        margin-right: 14rem;
        margin-left: -2.5rem;
      }

      @media (max-width: 992px) {
        width: 5rem;
        margin-right: 14rem;
        margin-left: -1.5rem;
      }

      @media (max-width: 768px) {
        width: 6rem;
        margin-right: 3rem;
        margin-left: -2rem;
      }
    }
    &-4 {
      width: 17.5rem;
      // margin-left: 2rem;
      display: flex;
      flex-wrap: wrap;

      @media (max-width: 768px) {
        width: 6rem;
      }

      &.woe-cus-col-4--amrap {
        // margin: 0 0.5rem 0.7rem 0.5rem;
        
        @media (max-width: 768px) {
          width: 100%;
        }
      }
    }
    &--reps-holder-1 {
      width: 3.5rem;
      display: inline-block;
    }
    &--reps-holder-2 {
      display: inline-block;
    }
    &--reps-holder-3 {
      display: inline-block;
    }
    &-5 {
      width: 6rem;
      margin-left: 1rem;
      
      @media (max-width: 768px) {
        width: 4.8rem;
        margin-left: 0;
      }
    }
    &-6 {
      width: 3rem;
    }
    &-7 {
      width: 1.7rem;

      .btn-icon {
        // width: 23px;
      }
    }
  }
  &.routine-woe-sets-row--preview {
    .woe-cus-col {
      &-1 {
        width: 2rem;
        @media (max-width: 768px) {
          width: 1.6rem;
        }
      }
      &-2 {
        width: 5rem;
        margin-right: 0.3rem;

        @media (max-width: 768px) {
          width: 4.5rem;
          margin-right: 0rem;
        }
      }
      &-3 {
        margin-right: 0.3rem;
        width: 4rem;

        @media (max-width: 768px) {
          margin-right: 0.3rem;
          width: 3rem;
        }
      }
      &-3-4 {
        width: 6rem;
        margin-right: 13rem;
        margin-left: 2.5rem;

        @media (max-width: 1199px) {
          width: 6rem;
          margin-right: 13rem;
          margin-left: 0.5rem;
        }

        @media (max-width: 992px) {
          width: 5rem;
          margin-right: 13rem;
          margin-left: 1rem;
        }

        @media (max-width: 768px) {
          width: 6rem;
          margin-right: 2rem;
          margin-left: 6rem;
        }
      }
      &-4 {
        @media (max-width: 768px) {
          width: 4.3rem;
        }
        &.woe-cus-col-4--amrap {
          @media (max-width: 768px) {
            width: 100%;
          }
        }
      }
      &-5 {
        width: 5rem;
        margin-left: 0.5rem;
        
        @media (max-width: 768px) {
          width: 4.2rem;
          margin-left: 0;
        }
      }
      &-6 {
        width: 3rem;
      }
      &-7 {
        width: 1.7rem;
      }
    }
  }
  &.routine-woe-sets-row--minimized {
    .po-highlight {
      border-radius: 6px;
      width: unset !important;
      padding-left: 0.5rem !important;
      padding-right: 0.5rem !important;
    }
    .amrap-highlight {
      white-space: nowrap;
      padding-left: 0.5rem !important;
      padding-right: 0.5rem !important;
      max-width: unset !important;
    }
  }
}
.woe-minimized {
  h4 {
    cursor: pointer;
  }
  .woe-minimized-set {
    cursor: pointer; 
  }
  .overload-box,
  .deload-box {
    display: inline-block;
    width: auto;
    padding: 0.5rem;
    cursor: pointer;
    
    h5 {
      margin-bottom: -2px;
      font-size: 1rem;
      font-weight: normal;
      text-transform: uppercase;
      letter-spacing: 0.5px;
      font-family: din-condensed, sans-serif;
    }
  }
}
.rep-text {
  display: inline-block;
}
.rep-number {
  width: 1.55rem;
  text-align: right;
  display: inline-block;
}
.rep-plus {
  display: inline-block;
  width: 0.8rem;
  text-align: right;
}
.card--deload,
.card--overload {
  border: 0;
  .card-body {
    padding-bottom: 0.75rem;
  }
}
.card--deload {
  background: #e9edf8;
}
.card--overload {
  background: #e2f2e1;
}
.amrap-tag,
.po-tag {
  display: inline-flex;
  align-items: center;
  border-radius: 6px;
  padding: 0px 8px 0px 8px;
  line-height: 20px;
  // margin-bottom: 3px;
  vertical-align: middle;
  font-size: 0.75rem;
  font-weight: normal;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  font-family: din-condensed, sans-serif;

  svg {
    height: 1.3em;
    width: 1.3em;
    top: 0.12em;
    position: relative;
    margin-right: 0.1em;
    margin-left: -2px;
  }

  .custom-switch {
    line-height: 1.8;
    cursor: pointer;

    .custom-control-input:checked ~ .custom-control-label::before {
      border-color: $amrap-color;
      background-color: $amrap-color;
    }
  }
}
.amrap-tag {
  background: $amrap-color;
  color: white;
  
  @media (min-width: 768px) {
    margin-left: 0.5rem;
  }
}
.po-tag {
  background: $po-color;
  span {
    @media (max-width: 768px) {
      display: none;
    }
  }
}
.amrap-box {
  line-height: 1.5;
  // background-color: $hardy-light-blue;

  h5 {
    letter-spacing: 0.5px;
    font-family: din-condensed, sans-serif;
    font-weight: 400;
    font-style: normal;
  }
}
.po-box {
  background: lighten($po-color, 25%);
  line-height: 1.5;
  text-align: left;
  // padding: 0.5rem;
  // padding-left: 1rem;
  // margin-left: -0.5rem;
  // margin-right: 0.5rem;

  .routine-woe-sets-header,
  .routine-woe-sets-row {
  }
  p {
    // font-size: 0.85rem;
    padding: 1rem 1rem;
  }
}
.deload-box,
.overload-box {
  padding: 0rem 1.5rem;
  border-radius: 6px;

  .routine-woe-sets-header {
    border-bottom: 0 !important;
    padding-top: 1rem;
    padding-bottom: 0 !important;;
  }
}
.deload-box {
  background: #ecf1fb;

  .add-plus {
    // background: #95b3ef;
  }
}
.overload-box {
  background: lighten($po-color, 30%);

  .add-plus {
    // background: $po-color;
  }
}
// .custom-radio-holder {
//   display: flex;
// }
// label.custom-radio-label {
//   flex-grow: 1;

//   input {
//     visibility: hidden;
//     position: absolute;
//   }
//   > div {
//     padding: 0.5rem 0.5rem;
//     border: 1px solid $input-border-color;
//     border-radius: $input-border-radius;
//     text-align: center;
//     font-weight: bold;
//   }
//   input:checked + div {
//     border-color: $hardy-brand-color;
//   }
//   span {
//   }
// }


.modal-title {
  font-family: din-condensed, sans-serif;
  font-weight: 400;
  font-style: normal;
  text-transform: uppercase;
}


.icon-exercise {
  height: 3em;
  width: 3em;
  vertical-align: middle;
  margin-right: 1rem;
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: center;
  display: inline-block;
  border-radius: 6px;
  background-color: $hardy-light-blue;
  flex-shrink: 0;

  &.icon-exercise--small {
    height: 2em;
    width: 2em;
    margin-right: 0.8rem;
  }

  &.icon-exercise--bordered {
    box-shadow: 0 0 0 1px rgba(200,200,200,0.3);
  }

  &.icon-exercise--barbell {
    background-image: url('../assets/icon-barbell.svg');
  }
  &.icon-exercise--dumbbell {
    background-image: url('../assets/icon-dumbbell.svg');
  }
  &.icon-exercise--kettlebell {
    background-image: url('../assets/icon-kettlebell.svg');
  }
  &.icon-exercise--plate {
    background-image: url('../assets/icon-plate.svg');
  }
  &.icon-exercise--machine {
    background-image: url('../assets/icon-machine.svg');
  }
  &.icon-exercise--bodyweight-time {
    background-image: url('../assets/icon-bodyweight-time.svg');
  }
  &.icon-exercise--bodyweight-rep {
    background-image: url('../assets/icon-bodyweight-rep.svg');
  }
}


.icon-deload,
.icon-overload {
  height: 2em;
  width: 2em;
  line-height: 1.5em;
  text-align: center;
  vertical-align: middle;
  margin-right: 0.5rem;
  display: inline-block;
  border-radius: 6px;
  background: $hardy-light-blue;
  padding: 0.2em;

  svg {
    // width: 100%;
  }
}
.icon-deload {
  background: #ecf1fb;
}
.icon-overload {
  background: lighten($po-color, 30%);
}

.appaccess-modal {
  .appaccess-modal-header {
    background-image: url('../assets/Hardy-app-screenshot-iphone-gym-workout-small.jpg');
    background-size: cover;
    background-position: center top;
    // padding-bottom: 100%;
    height: 100%;
    min-height: 300px;
    width: 100%;
    position: relative;
    border-top-left-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;

    @media (max-width: 992px) {
      border-top-right-radius: 0.5rem;
      border-bottom-left-radius: 0rem;
    }

    .btn-icon {
      position: absolute;
      right: 1rem;
      top: 1rem;
    }
  }
}


.cookie-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100vh;
  width: 100vw;
  background: transparentize($black, 0.5);
  overflow: hidden;
  z-index: 9998;
}
.cookie-banner {
  position: fixed;
  bottom: 2rem;
  right: 2rem;
  background: $black;
  box-shadow: 0 .25rem .5rem rgba(#000, .5);
  border-radius: 10px;
  padding: 2rem;
  color: rgba(255,255,255,0.9);
  max-width: 450px;
  z-index: 9999;
  animation: 0.5s css-fade-in;

  a {
    color: $hardy-brand-color;
  }
}