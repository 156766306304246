#routine-preview {
	h1 {
		line-height: 1.2;

		@media (max-width: 768px) {
	    font-size: 2rem;
	  }
	}

	.routine-holder {
		position: relative;
	}
	.your-stats-holder {
		@media (min-width: 992px) {
			position: absolute;
	    right: 0;
	    height: 100%;
	  }
	}
	.your-stats {

		@media (min-width: 992px) {
			position: -webkit-sticky; /* Safari */
		  position: sticky;
		  top: 120px;
		  margin-left: 2rem;
		}

		.your-stats-subtext {
			color: #949DAE;
			text-align: center;
		}
		.your-stats-label {
			text-transform: unset;
			font-family: din-2014, sans-serif;
			font-weight: 700;
		}
	}
	.routine-stats {
		// text-align: center;
		margin-top: 1rem;

		h3 {
			font-weight: lighter;
			margin-bottom: 0rem;
		}
		.btn-shadow {
			min-width: 7rem;
		}
	}
}
.startapp-modal {
	.startapp-modal-header {
		background-image: url('../../assets/Hardy-app-screenshot-iphone-gym-workout-small.jpg');
		background-size: cover;
		background-position: center top;
		padding-bottom: 66%;
		width: 100%;
		position: relative;
		border-top-right-radius: 0.5rem;
		border-top-left-radius: 0.5rem;

		.btn-icon {
			position: absolute;
			right: 1rem;
			top: 1rem;
		}
	}
}
.shareroutine-modal {
	.modal-content {
		overflow: hidden;
	}
	.nav {
		margin: -1rem;

		.nav-link {
			&:hover {
				background-color: #F4F6F9;
			}
		}
	}
}