@import "../../style/Colors";


@keyframes fadeIn {
	from {
		opacity: 0;
	}
}
@keyframes dropIn {
	from {
		transform: translateY(-10%);
		opacity: 0;
	}
}
@keyframes dropInDelayed {
	0% {
		opacity: 0;
	}
	75% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}
@keyframes dropInLaptopHolder {
	0% {
		opacity: 0;
		width: 20vw;
	}
	25% {
		opacity: 1;
		width: 20vw;
	}
	60% {
		width: 20vw;
	}
}
@keyframes dropInLaptopShape {
	0% {
		opacity: 0;
		border-radius: 1.5em;
	}
	10% {
		opacity: 1;
		border-radius: 1.5em;
	}
	25% {
		opacity: 1;
		border-radius: 1.5em;
	}
	50% {
		border-radius: 1.5em;
	}
	75% {
		border-radius: 1.5em;
	}
}
@keyframes dropInLaptopVideo {
	0% {
		opacity: 0;
	}
	50% {
		opacity: 0;
		padding-bottom: 150%;
	}
	90% {
		opacity: 0;
	}
	100% { opacity: 1; }
}
@keyframes dropInLaptopBottom {
	0% {
		left: 0;
		right: 0;
		transform: translateX(-10%);
		opacity: 0;
	}
	75% {
		left: 0;
		right: 0;
		transform: translateX(-10%);
		opacity: 0;
	}
}
@keyframes dropRotateInIcons {
	from {
		transform: translateX(-10%) translateY(-1.5vw) rotate(10deg);
		opacity: 0;
	}
}
@keyframes dropRotateInImgsLeft {
	from {
		transform: translateX(10%) translateY(-1.5vw) rotate(3deg);
	}
}
@keyframes dropRotateInImgsRight {
	from {
		transform: translateX(-10%) translateY(-1.5vw) rotate(-3deg);
	}
}

.hiw-hero {
	background-color: #000;
	color: $white;
	
	background-image: url('../../assets/hardy-bg-gradient-dark2.jpg');
	background-size: 100%;
	background-repeat: no-repeat;

	padding-bottom: 7vh;
	padding-top: 7vh;
	position: relative;
	margin-top: -80px;

	@media (max-width: 992px) {
		padding-bottom: 1rem;
	}
	.hiw-above-cards {
		background-image: url('../../assets/hardy-hand.png');
		background-size: 1580px;
		background-repeat: no-repeat;
		background-position: 50% 100%;
		// background-position: 50% 120%;
		position: relative;
		min-height: calc(1250px + 50vh);
		display: flex;
		justify-content: center;
		flex-direction: column;
		position: relative;

		&:before {
			content: '';
			display: block;
			position: absolute;
			left: 0;
			bottom: 0;
			width: 100%;
			height: 50%;
			background-image: linear-gradient(170deg, transparent 70%, #000 100%);
			z-index: 1;
			opacity: 1;
			pointer-events: none;
		}
	}
	.hero-phone-shape {
		width: 280px;
		height: 600px;
		position: absolute;
		left: 50%;
		bottom: 516px;
		background: #fff;
		border-radius: 40px;
		display: flex;
		flex-direction: row;
		align-items: flex-end;
		justify-content: flex-end;
		transform: translateY(0) translateX(-50%);
		overflow: hidden;

		// phone camera dynamic island
		// &:after {
		// 	content: '';
		// 	position: absolute;
		// 	top: 1%;
		// 	left: 50%;
		// 	transform: translateX(-50%);
		// 	background-color: #000;
		// 	width: 25%;
		// 	height: 3%;
		// 	border-radius: 100px;
		// }

		.video-holder {
			width: 100%;
			height: 0;
			padding-bottom: 206%;
		}
	}
	.hero-buttons {
		text-align: center;
		z-index: 3;
		position: relative;
		margin-top: auto;
	}
	.btn {
		height: 60px;
		color: white;
		padding-left: 2.2rem;
		padding-right: 2.2rem;
		text-transform: none;
		font-size: 22px;

		img {
			margin: -3px 0px 0 8px;
		}
		&.btn-hero-outline-primary {
			border-color: $hardy-brand-color;
			
			&:hover {
				border-color: $hardy-brand-color;
				background-color: transparent;
			}
		}
	}
	.hero-stats {
		display: flex;
		flex-direction: row;
		justify-content: center;
		margin-top: 80px;
		margin-bottom: 80px;
		z-index: 3;
		position: relative;
		
		.hero-stat {
			margin: 0 2.5%;
			color: #B6B4B3;
			letter-spacing: 0.2px;

			@media (max-width: 768px) {
				margin: 0 1%;
			}

			h4 {
				margin-bottom: 10px;
				color: #fff;
				font-size: 20px;

				svg {
					width: 18px;
					margin: -5px 2px 0 2px;
				}
			}
			p {
				font-size: 16px;
			}
		}
	}
}

.hiw-content {
  padding-top: 8rem;
  padding-bottom: 8rem;

  @media (max-width: 992px) {
    padding-top: 4rem;
  	padding-bottom: 4rem;
  }

  &.hiw-content--bigcta {
  	padding-top: 12rem;
  	padding-bottom: 12rem;
  }
}

h1.hero-headline {
	margin-top: 16vh;
	font-size: 4vw;
	max-width: 56.5vw;
	margin-left: auto;
	margin-right: auto;
	animation: dropIn 1s;

  @media (max-width: 992px) {
    font-size: 5vw;
    // margin-top: 4vh;
  }
	@media (max-width: 768px) {
		font-size: 7vw;
		max-width: 80vw;
	}
  @media (max-width: 600px) {
    font-size: 9vw;
		max-width: 80vw;
  }

	span {
		color: $hardy-brand-color;
	}

}
.content-headline {
	padding-top: 5rem;
	padding-bottom: 3rem;

	h2 {
		font-size: 4vw;
	  @media (max-width: 992px) {
	    font-size: 5vw;
	  }
	  @media (max-width: 768px) {
	    font-size: 7vw;
	  }

		span {
			color: $hardy-brand-color;
		}
	}
	h3 {
		font-weight: 300;
	}
	.lead {
		font-weight: 300;
		font-size: 2rem;
		margin-top: 3rem;
		opacity: 0.75;
	}
}

.content-subtitle {
	@media (max-width: 992px) {
    margin-top: 2rem;
  }
}
.content-title {
	font-size: 2.6rem;

	@media (max-width: 768px) {
    font-size: 1.7rem;
  }

  span {
  	color: $hardy-brand-color;
  }
}

.lead {
	font-size: 1.4rem;
}


.video-holder {
	height: 0;
	padding-bottom: 56%;
	position: relative;
	width: 100%;
	overflow: hidden;

	video, span {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		display: flex;
		justify-content: center;
		align-content: center;
		flex-direction: column;
	}
	span {
		opacity: 0.5;
	}
}
.laptop-shape {
	padding: 3%;
	box-shadow: 0 0 0 1px $hardy-brand-color;
  border-top-left-radius: 1.5em;
  border-top-right-radius: 1.5em;
  position: relative;
  margin-bottom: 0;
  margin-top: 0;

  &:after {
  	content: '';
  	display: block;
  	position: absolute;
  	bottom: -4%;
  	margin-bottom: -1px;
  	left: -10%;
  	right: -10%;
  	height: 4%;
  	box-shadow: 0 0 0 1px $hardy-brand-color;
    border-top-left-radius: 1em;
    border-top-right-radius: 1em;
    border-bottom-left-radius: 10em;
    border-bottom-right-radius: 10em;
  }
  .video-holder {
  	background: black;
  	border-radius: 0.5em;
  }
}
.phone-shape {
	padding: 3%;
	box-shadow: 0 0 0 2px $gray-200, inset 0 0 8px $gray-200;
  border-radius: 10%/5%;
  position: relative;
  margin-bottom: 5%;
  margin-top: 3%;
  // background-color: $gray-100;

  &.phone-shape--primary {
  	box-shadow: 0 0 0 2px $hardy-brand-color, inset 0 0 8px $hardy-brand-color;
  }

  .video-holder {
  	// padding-bottom: 57%;
  	padding-bottom: 206%;
  	background: white;
  	border-radius: 10%/5%;
  	overflow: hidden;

  	video {
  		border-radius: inherit;
  	}
  }
}
.tablet-shape {
	padding: 4%;
	box-shadow: 0 0 0 2px $gray-200, inset 0 0 8px $gray-200;
  border-radius: 1.5vw;
  position: relative;
  margin-bottom: 5%;
  margin-top: 3%;
  // background-color: rgba(255,255,255,0.1);

  .video-holder {
  	padding-bottom: 57%;
  	background: $black;
  	border-radius: 0.5em;
  }
}
.dual-video-holder {
	position: relative;
}
.generic-shape {
	padding: 5px 0px;
	// box-shadow: 0 0 0 2px $gray-100;
  border-radius: 10px;
  position: relative;
  margin-bottom: 5%;
  margin-top: 3%;
  background-color: #fff;
  border: 2px solid $gray-200;

  &.generic-shape--exercise {
	  .video-holder {
	  	padding-bottom: 140%;
	  }
	}
	&.generic-shape--exerciselist {
		padding: 0;
		overflow: hidden;

		.video-holder {
	  	padding-bottom: 104%;
	  }	
	}
	&.generic-shape--sets {
		.video-holder {
	  	padding-bottom: 108%;
	  }
	}
	&.generic-shape--mini {
		padding: 0;
		position: absolute;
		top: -22%;
		right: -12%;
		width: 50%;
		box-shadow: 0 0 30px rgba(150,150,150,0.1), 0 0 10px rgba(150,150,150,0.2);;
		z-index: 3;
		overflow: hidden;
		border: 0;

		@media (max-width: 768px) {
	    right: 7%;
	    top: -26%;
	  }
		
		.video-holder {
	  	padding-bottom: 82%;
	  }
	}
}
.phone-holder-angled {
	// position: absolute;
	// right: 10%;
	// top: -5%;
	// overflow: hidden;
	// height: 400px;
	width: 100%;
	perspective: 500px;
  perspective-origin: 50% 50%;

  &.phone-holder-angled--absolute {
  	position: absolute;
		right: 10%;
		top: -5%;
		// overflow: hidden;
		height: 400px;
		width: 30%;

		@media (max-width: 992px) {
	    position: relative;
	    width: 60%;
	    margin: 0 auto;
	    height: 0;
	    padding-bottom: 100%;
	  }
	  @media (max-width: 768px) {
	    width: 80%;
	    padding-bottom: 80%;
	  }
  }

  &.phone-holder-angled--free {
  	width: 100%;
  	position: relative;
  	top: 0;
  	right: 0;
  }
	.phone-angled {
  	transform: rotateX(10deg) rotateY(-20deg) rotateZ(15deg) scale(0.85);
	}

	&.phone-holder-angled--two {
		margin-top: -20%;
		margin-bottom: -10%;

		@media (max-width: 992px) {
			width: 60%;
			margin-top: 0;
			margin-left: auto;
			margin-right: auto;
		}

		.phone-angled {
			transform: rotateX(10deg) rotateY(-20deg) rotateZ(15deg) scale(0.85) translateX(-10%);	
		}
	}
	.phone-shape {
		padding: 3% 7% 3% 3%;
		border-radius: 12%/7%;

		.video-holder {
			box-shadow: 0 0 15px $hardy-brand-color;
		}

		&:before {
			content: '';
			display: block;
			width: 2%;
			height: 15%;
			border-radius: 10px;
			box-shadow: 0 0 0 1px $hardy-brand-color, inset 0 0 3px $hardy-brand-color;
			position: absolute;
			top: 30%;
			right: 2.3%;
			opacity: 0.6;
		}
	}

	&.phone-holder-angled--white {
  	.phone-shape {
			.video-holder {
				box-shadow: 0 0 15px $gray-200;
			}
			&:before {
				box-shadow: 0 0 0 1px $gray-200, inset 0 0 3px $gray-200;
			}
		}
  }

  &.phone-holder-angled--mobilenotangled {

  	@media (max-width: 992px) {
	  	right: 0%;
			top: 0%;
			margin-top: 15%;

			.phone-angled {
				transform: rotateX(0deg) rotateY(0deg) rotateZ(0deg) scale(1) translateX(0%);	
			}
			.phone-shape {
				padding: 3%;
				border-radius: 10%/5%;

				.video-holder {
					border-radius: 10%/5%;
					box-shadow: 0 0 15px $hardy-brand-color;
				}
				
				&:before {
					display: none;
				}
			}
		}
  }
}
.checklist {
	list-style: none;
	padding: 0;
	margin: 3rem 0 0 0;

	li {
		margin-bottom: 0.5rem;

		svg {
			margin-right: 1rem;
		}
	}
}
.card-link {
	&:hover {
		text-decoration: none;
	}
}
.hiw-cta-card {
	background-color: #1D1D1D;
	padding: 0rem 2rem;
	// margin: 0 2rem;
	color: white;
	position: relative;
	overflow: hidden;
	border: 0;

	@media (max-width: 768px) {
    margin: 0 1rem;
    padding: 1rem 0rem;
  }

	@media (max-width: 992px) {
		text-align: center;
	}
	
	.content-subtitle {
		color: $hardy-brand-color;
		font-weight: normal;
	}


	// &:before {
	// 	content: '';
	// 	background-image: url('../../assets/Hardy-app-screenshot-iphone-gym-workout-small.jpg');
	// 	background-size: cover;
	// 	display: block;
	// 	width: 100%;
	// 	height: 100%;
	// 	position: absolute;
	// 	top: 0;
	// 	left: 0;
	// 	opacity: 0.04;
	// }
	&:after {
    // content: '';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(170deg, transparent 30%, $hardy-brand-color 300%);
    z-index: 9;
    opacity: 0.6;
    pointer-events: none;
  }
  .row {
  	z-index: 1;
  	position: relative;
  }
  &.hiw-cta-card--cta:after {
  	z-index: 0;
  }
	.text-muted {
		opacity: 0.6;
	}
	p.lead {
		opacity: 0.6;
	}
	.btn-outline-secondary {
		color: $gray-500;
		opacity: 0.6;
		border-color: transparent;

		&:hover {
			color: $hardy-brand-color;
			opacity: 1;
			border-color: $hardy-brand-color;
		}
	}
}
.extra-callout {
	position: absolute;
	top: 130px;
	right: 50px;

	.arrow-icon {
		width: 30px;
		height: 30px;
		margin-left: 15px;
		margin-top: 10px;
		transform: rotate(-20deg);
	}
	p {
		color: $hardy-brand-color;
		margin-bottom: 0;
		width: 100px;
	}
}
.faq-scrolloffset {
	margin-top: -8rem;
	padding-top: 8rem;
	visibility: hidden;
}
.coming-soon-box {
	text-align: center;
	display: flex;
	min-height: 70px;
  align-items: center;
  justify-content: center;
  align-content: center;
  // border: 1px solid $gray-300;
  padding: 0.5rem;
  height: 100%;
  border-radius: 15px;
  // color: $gray-200, inset 0 0 8px $gray-200;
  font-size: 1.2rem;
  // font-weight: 300;
  background-color: transparentize($black, 0.6);
  color: $white;

  &.coming-soon-box--done {
  	background-color: $success;
  }

  svg {
  	margin-right: 1rem;
  }
}
.reddit-cta-card {
	// background-color: rgba(0,0,0,0.885);
	padding: 2rem 2rem;
	// color: white;
	border: 0;
	position: relative;
	transition: background 0.5s, color 0.5s;
	overflow: hidden;

	@media (max-width: 768px) {
    margin: 0 1rem;
    padding: 4rem 1rem;
  }

  &:hover {
  	text-decoration: none;
  }
	&:after {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(170deg, transparent 30%, $black 300%);
    z-index: 0;
    opacity: 0.6;
  }
  .row {
  	z-index: 1;
  	position: relative;
  }

	.text-muted {
		opacity: 0.6;
	}
	p.lead {
		opacity: 0.6;
	}
	.reddit-icon {
		background: #FF5700;
		border-radius: 100px;
		width: 110px;
		height: 110px;
		display: inline-flex;
    align-content: center;
    justify-content: center;

		img {
			width: 60%;
		}
	}
}

.floating-phones-cta-img {
	width: 100%;
	max-width: 100%;
}

.hardy-app-demo-img {
	margin: 2rem auto 0 auto;
	width: 80%;
	overflow: hidden;
	border-radius: 0.6rem;
}
.dotted-underline {
	border-bottom: 1px dashed $gray-500;
}